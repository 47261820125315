function e(e, t, s) {
  return t in e ? Object.defineProperty(e, t, {
    value: s,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[t] = s, e;
}
var t = "undefined" != typeof self ? self : global;
const s = "undefined" != typeof navigator,
  i = s && "undefined" == typeof HTMLImageElement,
  n = !("undefined" == typeof global || "undefined" == typeof process || !process.versions || !process.versions.node),
  r = t.Buffer,
  a = !!r,
  h = e => void 0 !== e;
function f(e) {
  return void 0 === e || (e instanceof Map ? 0 === e.size : 0 === Object.values(e).filter(h).length);
}
function l(e) {
  let t = new Error(e);
  throw delete t.stack, t;
}
function o(e) {
  let t = function (e) {
    let t = 0;
    return e.ifd0.enabled && (t += 1024), e.exif.enabled && (t += 2048), e.makerNote && (t += 2048), e.userComment && (t += 1024), e.gps.enabled && (t += 512), e.interop.enabled && (t += 100), e.ifd1.enabled && (t += 1024), t + 2048;
  }(e);
  return e.jfif.enabled && (t += 50), e.xmp.enabled && (t += 2e4), e.iptc.enabled && (t += 14e3), e.icc.enabled && (t += 6e3), t;
}
const u = e => String.fromCharCode.apply(null, e),
  d = "undefined" != typeof TextDecoder ? new TextDecoder("utf-8") : void 0;
class c {
  static from(e, t) {
    return e instanceof this && e.le === t ? e : new c(e, void 0, void 0, t);
  }
  constructor(e, t = 0, s, i) {
    if ("boolean" == typeof i && (this.le = i), Array.isArray(e) && (e = new Uint8Array(e)), 0 === e) this.byteOffset = 0, this.byteLength = 0;else if (e instanceof ArrayBuffer) {
      void 0 === s && (s = e.byteLength - t);
      let i = new DataView(e, t, s);
      this._swapDataView(i);
    } else if (e instanceof Uint8Array || e instanceof DataView || e instanceof c) {
      void 0 === s && (s = e.byteLength - t), (t += e.byteOffset) + s > e.byteOffset + e.byteLength && l("Creating view outside of available memory in ArrayBuffer");
      let i = new DataView(e.buffer, t, s);
      this._swapDataView(i);
    } else if ("number" == typeof e) {
      let t = new DataView(new ArrayBuffer(e));
      this._swapDataView(t);
    } else l("Invalid input argument for BufferView: " + e);
  }
  _swapArrayBuffer(e) {
    this._swapDataView(new DataView(e));
  }
  _swapBuffer(e) {
    this._swapDataView(new DataView(e.buffer, e.byteOffset, e.byteLength));
  }
  _swapDataView(e) {
    this.dataView = e, this.buffer = e.buffer, this.byteOffset = e.byteOffset, this.byteLength = e.byteLength;
  }
  _lengthToEnd(e) {
    return this.byteLength - e;
  }
  set(e, t, s = c) {
    return e instanceof DataView || e instanceof c ? e = new Uint8Array(e.buffer, e.byteOffset, e.byteLength) : e instanceof ArrayBuffer && (e = new Uint8Array(e)), e instanceof Uint8Array || l("BufferView.set(): Invalid data argument."), this.toUint8().set(e, t), new s(this, t, e.byteLength);
  }
  subarray(e, t) {
    return t = t || this._lengthToEnd(e), new c(this, e, t);
  }
  toUint8() {
    return new Uint8Array(this.buffer, this.byteOffset, this.byteLength);
  }
  getUint8Array(e, t) {
    return new Uint8Array(this.buffer, this.byteOffset + e, t);
  }
  getString(e = 0, t = this.byteLength) {
    let s = this.getUint8Array(e, t);
    return i = s, d ? d.decode(i) : a ? Buffer.from(i).toString("utf8") : decodeURIComponent(escape(u(i)));
    var i;
  }
  getLatin1String(e = 0, t = this.byteLength) {
    let s = this.getUint8Array(e, t);
    return u(s);
  }
  getUnicodeString(e = 0, t = this.byteLength) {
    const s = [];
    for (let i = 0; i < t && e + i < this.byteLength; i += 2) s.push(this.getUint16(e + i));
    return u(s);
  }
  getInt8(e) {
    return this.dataView.getInt8(e);
  }
  getUint8(e) {
    return this.dataView.getUint8(e);
  }
  getInt16(e, t = this.le) {
    return this.dataView.getInt16(e, t);
  }
  getInt32(e, t = this.le) {
    return this.dataView.getInt32(e, t);
  }
  getUint16(e, t = this.le) {
    return this.dataView.getUint16(e, t);
  }
  getUint32(e, t = this.le) {
    return this.dataView.getUint32(e, t);
  }
  getFloat32(e, t = this.le) {
    return this.dataView.getFloat32(e, t);
  }
  getFloat64(e, t = this.le) {
    return this.dataView.getFloat64(e, t);
  }
  getFloat(e, t = this.le) {
    return this.dataView.getFloat32(e, t);
  }
  getDouble(e, t = this.le) {
    return this.dataView.getFloat64(e, t);
  }
  getUintBytes(e, t, s) {
    switch (t) {
      case 1:
        return this.getUint8(e, s);
      case 2:
        return this.getUint16(e, s);
      case 4:
        return this.getUint32(e, s);
      case 8:
        return this.getUint64 && this.getUint64(e, s);
    }
  }
  getUint(e, t, s) {
    switch (t) {
      case 8:
        return this.getUint8(e, s);
      case 16:
        return this.getUint16(e, s);
      case 32:
        return this.getUint32(e, s);
      case 64:
        return this.getUint64 && this.getUint64(e, s);
    }
  }
  toString(e) {
    return this.dataView.toString(e, this.constructor.name);
  }
  ensureChunk() {}
}
function p(e, t) {
  l(`${e} '${t}' was not loaded, try using full build of exifr.`);
}
class g extends Map {
  constructor(e) {
    super(), this.kind = e;
  }
  get(e, t) {
    return this.has(e) || p(this.kind, e), t && (e in t || function (e, t) {
      l(`Unknown ${e} '${t}'.`);
    }(this.kind, e), t[e].enabled || p(this.kind, e)), super.get(e);
  }
  keyList() {
    return Array.from(this.keys());
  }
}
var m = new g("file parser"),
  y = new g("segment parser"),
  b = new g("file reader");
let w = t.fetch;
function k(e, t) {
  return (i = e).startsWith("data:") || i.length > 1e4 ? v(e, t, "base64") : n && e.includes("://") ? O(e, t, "url", S) : n ? v(e, t, "fs") : s ? O(e, t, "url", S) : void l("Invalid input argument");
  var i;
}
async function O(e, t, s, i) {
  return b.has(s) ? v(e, t, s) : i ? async function (e, t) {
    let s = await t(e);
    return new c(s);
  }(e, i) : void l(`Parser ${s} is not loaded`);
}
async function v(e, t, s) {
  let i = new (b.get(s))(e, t);
  return await i.read(), i;
}
const S = e => w(e).then(e => e.arrayBuffer()),
  A = e => new Promise((t, s) => {
    let i = new FileReader();
    i.onloadend = () => t(i.result || new ArrayBuffer()), i.onerror = s, i.readAsArrayBuffer(e);
  });
class U extends Map {
  get tagKeys() {
    return this.allKeys || (this.allKeys = Array.from(this.keys())), this.allKeys;
  }
  get tagValues() {
    return this.allValues || (this.allValues = Array.from(this.values())), this.allValues;
  }
}
function x(e, t, s) {
  let i = new U();
  for (let [e, t] of s) i.set(e, t);
  if (Array.isArray(t)) for (let s of t) e.set(s, i);else e.set(t, i);
  return i;
}
function C(e, t, s) {
  let i,
    n = e.get(t);
  for (i of s) n.set(i[0], i[1]);
}
const B = new Map(),
  V = new Map(),
  I = new Map(),
  L = ["chunked", "firstChunkSize", "firstChunkSizeNode", "firstChunkSizeBrowser", "chunkSize", "chunkLimit"],
  T = ["jfif", "xmp", "icc", "iptc", "ihdr"],
  z = ["tiff", ...T],
  P = ["ifd0", "ifd1", "exif", "gps", "interop"],
  F = [...z, ...P],
  j = ["makerNote", "userComment"],
  E = ["translateKeys", "translateValues", "reviveValues", "multiSegment"],
  M = [...E, "sanitize", "mergeOutput", "silentErrors"];
class _ {
  get translate() {
    return this.translateKeys || this.translateValues || this.reviveValues;
  }
}
class D extends _ {
  get needed() {
    return this.enabled || this.deps.size > 0;
  }
  constructor(t, s, i, n) {
    if (super(), e(this, "enabled", !1), e(this, "skip", new Set()), e(this, "pick", new Set()), e(this, "deps", new Set()), e(this, "translateKeys", !1), e(this, "translateValues", !1), e(this, "reviveValues", !1), this.key = t, this.enabled = s, this.parse = this.enabled, this.applyInheritables(n), this.canBeFiltered = P.includes(t), this.canBeFiltered && (this.dict = B.get(t)), void 0 !== i) if (Array.isArray(i)) this.parse = this.enabled = !0, this.canBeFiltered && i.length > 0 && this.translateTagSet(i, this.pick);else if ("object" == typeof i) {
      if (this.enabled = !0, this.parse = !1 !== i.parse, this.canBeFiltered) {
        let {
          pick: e,
          skip: t
        } = i;
        e && e.length > 0 && this.translateTagSet(e, this.pick), t && t.length > 0 && this.translateTagSet(t, this.skip);
      }
      this.applyInheritables(i);
    } else !0 === i || !1 === i ? this.parse = this.enabled = i : l(`Invalid options argument: ${i}`);
  }
  applyInheritables(e) {
    let t, s;
    for (t of E) s = e[t], void 0 !== s && (this[t] = s);
  }
  translateTagSet(e, t) {
    if (this.dict) {
      let s,
        i,
        {
          tagKeys: n,
          tagValues: r
        } = this.dict;
      for (s of e) "string" == typeof s ? (i = r.indexOf(s), -1 === i && (i = n.indexOf(Number(s))), -1 !== i && t.add(Number(n[i]))) : t.add(s);
    } else for (let s of e) t.add(s);
  }
  finalizeFilters() {
    !this.enabled && this.deps.size > 0 ? (this.enabled = !0, X(this.pick, this.deps)) : this.enabled && this.pick.size > 0 && X(this.pick, this.deps);
  }
}
var N = {
    jfif: !1,
    tiff: !0,
    xmp: !1,
    icc: !1,
    iptc: !1,
    ifd0: !0,
    ifd1: !1,
    exif: !0,
    gps: !0,
    interop: !1,
    ihdr: void 0,
    makerNote: !1,
    userComment: !1,
    multiSegment: !1,
    skip: [],
    pick: [],
    translateKeys: !0,
    translateValues: !0,
    reviveValues: !0,
    sanitize: !0,
    mergeOutput: !0,
    silentErrors: !0,
    chunked: !0,
    firstChunkSize: void 0,
    firstChunkSizeNode: 512,
    firstChunkSizeBrowser: 65536,
    chunkSize: 65536,
    chunkLimit: 5
  },
  $ = new Map();
class R extends _ {
  static useCached(e) {
    let t = $.get(e);
    return void 0 !== t || (t = new this(e), $.set(e, t)), t;
  }
  constructor(e) {
    super(), !0 === e ? this.setupFromTrue() : void 0 === e ? this.setupFromUndefined() : Array.isArray(e) ? this.setupFromArray(e) : "object" == typeof e ? this.setupFromObject(e) : l(`Invalid options argument ${e}`), void 0 === this.firstChunkSize && (this.firstChunkSize = s ? this.firstChunkSizeBrowser : this.firstChunkSizeNode), this.mergeOutput && (this.ifd1.enabled = !1), this.filterNestedSegmentTags(), this.traverseTiffDependencyTree(), this.checkLoadedPlugins();
  }
  setupFromUndefined() {
    let e;
    for (e of L) this[e] = N[e];
    for (e of M) this[e] = N[e];
    for (e of j) this[e] = N[e];
    for (e of F) this[e] = new D(e, N[e], void 0, this);
  }
  setupFromTrue() {
    let e;
    for (e of L) this[e] = N[e];
    for (e of M) this[e] = N[e];
    for (e of j) this[e] = !0;
    for (e of F) this[e] = new D(e, !0, void 0, this);
  }
  setupFromArray(e) {
    let t;
    for (t of L) this[t] = N[t];
    for (t of M) this[t] = N[t];
    for (t of j) this[t] = N[t];
    for (t of F) this[t] = new D(t, !1, void 0, this);
    this.setupGlobalFilters(e, void 0, P);
  }
  setupFromObject(e) {
    let t;
    for (t of (P.ifd0 = P.ifd0 || P.image, P.ifd1 = P.ifd1 || P.thumbnail, Object.assign(this, e), L)) this[t] = W(e[t], N[t]);
    for (t of M) this[t] = W(e[t], N[t]);
    for (t of j) this[t] = W(e[t], N[t]);
    for (t of z) this[t] = new D(t, N[t], e[t], this);
    for (t of P) this[t] = new D(t, N[t], e[t], this.tiff);
    this.setupGlobalFilters(e.pick, e.skip, P, F), !0 === e.tiff ? this.batchEnableWithBool(P, !0) : !1 === e.tiff ? this.batchEnableWithUserValue(P, e) : Array.isArray(e.tiff) ? this.setupGlobalFilters(e.tiff, void 0, P) : "object" == typeof e.tiff && this.setupGlobalFilters(e.tiff.pick, e.tiff.skip, P);
  }
  batchEnableWithBool(e, t) {
    for (let s of e) this[s].enabled = t;
  }
  batchEnableWithUserValue(e, t) {
    for (let s of e) {
      let e = t[s];
      this[s].enabled = !1 !== e && void 0 !== e;
    }
  }
  setupGlobalFilters(e, t, s, i = s) {
    if (e && e.length) {
      for (let e of i) this[e].enabled = !1;
      let t = K(e, s);
      for (let [e, s] of t) X(this[e].pick, s), this[e].enabled = !0;
    } else if (t && t.length) {
      let e = K(t, s);
      for (let [t, s] of e) X(this[t].skip, s);
    }
  }
  filterNestedSegmentTags() {
    let {
      ifd0: e,
      exif: t,
      xmp: s,
      iptc: i,
      icc: n
    } = this;
    this.makerNote ? t.deps.add(37500) : t.skip.add(37500), this.userComment ? t.deps.add(37510) : t.skip.add(37510), s.enabled || e.skip.add(700), i.enabled || e.skip.add(33723), n.enabled || e.skip.add(34675);
  }
  traverseTiffDependencyTree() {
    let {
      ifd0: e,
      exif: t,
      gps: s,
      interop: i
    } = this;
    i.needed && (t.deps.add(40965), e.deps.add(40965)), t.needed && e.deps.add(34665), s.needed && e.deps.add(34853), this.tiff.enabled = P.some(e => !0 === this[e].enabled) || this.makerNote || this.userComment;
    for (let e of P) this[e].finalizeFilters();
  }
  get onlyTiff() {
    return !T.map(e => this[e].enabled).some(e => !0 === e) && this.tiff.enabled;
  }
  checkLoadedPlugins() {
    for (let e of z) this[e].enabled && !y.has(e) && p("segment parser", e);
  }
}
function K(e, t) {
  let s,
    i,
    n,
    r,
    a = [];
  for (n of t) {
    for (r of (s = B.get(n), i = [], s)) (e.includes(r[0]) || e.includes(r[1])) && i.push(r[0]);
    i.length && a.push([n, i]);
  }
  return a;
}
function W(e, t) {
  return void 0 !== e ? e : void 0 !== t ? t : void 0;
}
function X(e, t) {
  for (let s of t) e.add(s);
}
e(R, "default", N);
class H {
  constructor(t) {
    e(this, "parsers", {}), e(this, "output", {}), e(this, "errors", []), e(this, "pushToErrors", e => this.errors.push(e)), this.options = R.useCached(t);
  }
  async read(e) {
    this.file = await function (e, t) {
      return "string" == typeof e ? k(e, t) : s && !i && e instanceof HTMLImageElement ? k(e.src, t) : e instanceof Uint8Array || e instanceof ArrayBuffer || e instanceof DataView ? new c(e) : s && e instanceof Blob ? O(e, t, "blob", A) : void l("Invalid input argument");
    }(e, this.options);
  }
  setup() {
    if (this.fileParser) return;
    let {
        file: e
      } = this,
      t = e.getUint16(0);
    for (let [s, i] of m) if (i.canHandle(e, t)) return this.fileParser = new i(this.options, this.file, this.parsers), e[s] = !0;
    this.file.close && this.file.close(), l("Unknown file format");
  }
  async parse() {
    let {
      output: e,
      errors: t
    } = this;
    return this.setup(), this.options.silentErrors ? (await this.executeParsers().catch(this.pushToErrors), t.push(...this.fileParser.errors)) : await this.executeParsers(), this.file.close && this.file.close(), this.options.silentErrors && t.length > 0 && (e.errors = t), f(s = e) ? void 0 : s;
    var s;
  }
  async executeParsers() {
    let {
      output: e
    } = this;
    await this.fileParser.parse();
    let t = Object.values(this.parsers).map(async t => {
      let s = await t.parse();
      t.assignToOutput(e, s);
    });
    this.options.silentErrors && (t = t.map(e => e.catch(this.pushToErrors))), await Promise.all(t);
  }
  async extractThumbnail() {
    this.setup();
    let {
        options: e,
        file: t
      } = this,
      s = y.get("tiff", e);
    var i;
    if (t.tiff ? i = {
      start: 0,
      type: "tiff"
    } : t.jpeg && (i = await this.fileParser.getOrFindSegment("tiff")), void 0 === i) return;
    let n = await this.fileParser.ensureSegmentChunk(i),
      r = this.parsers.tiff = new s(n, e, t),
      a = await r.extractThumbnail();
    return t.close && t.close(), a;
  }
}
async function Y(e, t) {
  let s = new H(t);
  return await s.read(e), s.parse();
}
var G = Object.freeze({
  __proto__: null,
  parse: Y,
  Exifr: H,
  fileParsers: m,
  segmentParsers: y,
  fileReaders: b,
  tagKeys: B,
  tagValues: V,
  tagRevivers: I,
  createDictionary: x,
  extendDictionary: C,
  fetchUrlAsArrayBuffer: S,
  readBlobAsArrayBuffer: A,
  chunkedProps: L,
  otherSegments: T,
  segments: z,
  tiffBlocks: P,
  segmentsAndBlocks: F,
  tiffExtractables: j,
  inheritables: E,
  allFormatters: M,
  Options: R
});
class J {
  static findPosition(e, t) {
    let s = e.getUint16(t + 2) + 2,
      i = "function" == typeof this.headerLength ? this.headerLength(e, t, s) : this.headerLength,
      n = t + i,
      r = s - i;
    return {
      offset: t,
      length: s,
      headerLength: i,
      start: n,
      size: r,
      end: n + r
    };
  }
  static parse(e, t = {}) {
    return new this(e, new R({
      [this.type]: t
    }), e).parse();
  }
  normalizeInput(e) {
    return e instanceof c ? e : new c(e);
  }
  constructor(t, s = {}, i) {
    e(this, "errors", []), e(this, "raw", new Map()), e(this, "handleError", e => {
      if (!this.options.silentErrors) throw e;
      this.errors.push(e.message);
    }), this.chunk = this.normalizeInput(t), this.file = i, this.type = this.constructor.type, this.globalOptions = this.options = s, this.localOptions = s[this.type], this.canTranslate = this.localOptions && this.localOptions.translate;
  }
  translate() {
    this.canTranslate && (this.translated = this.translateBlock(this.raw, this.type));
  }
  get output() {
    return this.translated ? this.translated : this.raw ? Object.fromEntries(this.raw) : void 0;
  }
  translateBlock(e, t) {
    let s = I.get(t),
      i = V.get(t),
      n = B.get(t),
      r = this.options[t],
      a = r.reviveValues && !!s,
      h = r.translateValues && !!i,
      f = r.translateKeys && !!n,
      l = {};
    for (let [t, r] of e) a && s.has(t) ? r = s.get(t)(r) : h && i.has(t) && (r = this.translateValue(r, i.get(t))), f && n.has(t) && (t = n.get(t) || t), l[t] = r;
    return l;
  }
  translateValue(e, t) {
    return t[e] || t.DEFAULT || e;
  }
  assignToOutput(e, t) {
    this.assignObjectToOutput(e, this.constructor.type, t);
  }
  assignObjectToOutput(e, t, s) {
    if (this.globalOptions.mergeOutput) return Object.assign(e, s);
    e[t] ? Object.assign(e[t], s) : e[t] = s;
  }
}
e(J, "headerLength", 4), e(J, "type", void 0), e(J, "multiSegment", !1), e(J, "canHandle", () => !1);
function q(e) {
  return 192 === e || 194 === e || 196 === e || 219 === e || 221 === e || 218 === e || 254 === e;
}
function Q(e) {
  return e >= 224 && e <= 239;
}
function Z(e, t, s) {
  for (let [i, n] of y) if (n.canHandle(e, t, s)) return i;
}
class ee extends class {
  constructor(t, s, i) {
    e(this, "errors", []), e(this, "ensureSegmentChunk", async e => {
      let t = e.start,
        s = e.size || 65536;
      if (this.file.chunked) {
        if (this.file.available(t, s)) e.chunk = this.file.subarray(t, s);else try {
          e.chunk = await this.file.readChunk(t, s);
        } catch (t) {
          l(`Couldn't read segment: ${JSON.stringify(e)}. ${t.message}`);
        }
      } else this.file.byteLength > t + s ? e.chunk = this.file.subarray(t, s) : void 0 === e.size ? e.chunk = this.file.subarray(t) : l("Segment unreachable: " + JSON.stringify(e));
      return e.chunk;
    }), this.extendOptions && this.extendOptions(t), this.options = t, this.file = s, this.parsers = i;
  }
  injectSegment(e, t) {
    this.options[e].enabled && this.createParser(e, t);
  }
  createParser(e, t) {
    let s = new (y.get(e))(t, this.options, this.file);
    return this.parsers[e] = s;
  }
  createParsers(e) {
    for (let t of e) {
      let {
          type: e,
          chunk: s
        } = t,
        i = this.options[e];
      if (i && i.enabled) {
        let t = this.parsers[e];
        t && t.append || t || this.createParser(e, s);
      }
    }
  }
  async readSegments(e) {
    let t = e.map(this.ensureSegmentChunk);
    await Promise.all(t);
  }
} {
  constructor(...t) {
    super(...t), e(this, "appSegments", []), e(this, "jpegSegments", []), e(this, "unknownSegments", []);
  }
  static canHandle(e, t) {
    return 65496 === t;
  }
  async parse() {
    await this.findAppSegments(), await this.readSegments(this.appSegments), this.mergeMultiSegments(), this.createParsers(this.mergedAppSegments || this.appSegments);
  }
  setupSegmentFinderArgs(e) {
    !0 === e ? (this.findAll = !0, this.wanted = new Set(y.keyList())) : (e = void 0 === e ? y.keyList().filter(e => this.options[e].enabled) : e.filter(e => this.options[e].enabled && y.has(e)), this.findAll = !1, this.remaining = new Set(e), this.wanted = new Set(e)), this.unfinishedMultiSegment = !1;
  }
  async findAppSegments(e = 0, t) {
    this.setupSegmentFinderArgs(t);
    let {
      file: s,
      findAll: i,
      wanted: n,
      remaining: r
    } = this;
    if (!i && this.file.chunked && (i = Array.from(n).some(e => {
      let t = y.get(e),
        s = this.options[e];
      return t.multiSegment && s.multiSegment;
    }), i && (await this.file.readWhole())), e = this.findAppSegmentsInRange(e, s.byteLength), !this.options.onlyTiff && s.chunked) {
      let t = !1;
      for (; r.size > 0 && !t && (s.canReadNextChunk || this.unfinishedMultiSegment);) {
        let {
            nextChunkOffset: i
          } = s,
          n = this.appSegments.some(e => !this.file.available(e.offset || e.start, e.length || e.size));
        if (t = e > i && !n ? !(await s.readNextChunk(e)) : !(await s.readNextChunk(i)), void 0 === (e = this.findAppSegmentsInRange(e, s.byteLength))) return;
      }
    }
  }
  findAppSegmentsInRange(e, t) {
    t -= 2;
    let s,
      i,
      n,
      r,
      a,
      h,
      {
        file: f,
        findAll: l,
        wanted: o,
        remaining: u,
        options: d
      } = this;
    for (; e < t; e++) if (255 === f.getUint8(e)) if (s = f.getUint8(e + 1), Q(s)) {
      if (i = f.getUint16(e + 2), n = Z(f, e, i), n && o.has(n) && (r = y.get(n), a = r.findPosition(f, e), h = d[n], a.type = n, this.appSegments.push(a), !l && (r.multiSegment && h.multiSegment ? (this.unfinishedMultiSegment = a.chunkNumber < a.chunkCount, this.unfinishedMultiSegment || u.delete(n)) : u.delete(n), 0 === u.size))) break;
      d.recordUnknownSegments && (a = J.findPosition(f, e), a.marker = s, this.unknownSegments.push(a)), e += i + 1;
    } else if (q(s)) {
      if (i = f.getUint16(e + 2), 218 === s && !1 !== d.stopAfterSos) return;
      d.recordJpegSegments && this.jpegSegments.push({
        offset: e,
        length: i,
        marker: s
      }), e += i + 1;
    }
    return e;
  }
  mergeMultiSegments() {
    if (!this.appSegments.some(e => e.multiSegment)) return;
    let e = function (e, t) {
      let s,
        i,
        n,
        r = new Map();
      for (let a = 0; a < e.length; a++) s = e[a], i = s[t], r.has(i) ? n = r.get(i) : r.set(i, n = []), n.push(s);
      return Array.from(r);
    }(this.appSegments, "type");
    this.mergedAppSegments = e.map(([e, t]) => {
      let s = y.get(e, this.options);
      if (s.handleMultiSegments) {
        return {
          type: e,
          chunk: s.handleMultiSegments(t)
        };
      }
      return t[0];
    });
  }
  getSegment(e) {
    return this.appSegments.find(t => t.type === e);
  }
  async getOrFindSegment(e) {
    let t = this.getSegment(e);
    return void 0 === t && (await this.findAppSegments(0, [e]), t = this.getSegment(e)), t;
  }
}
e(ee, "type", "jpeg"), m.set("jpeg", ee);
const te = [void 0, 1, 1, 2, 4, 8, 1, 1, 2, 4, 8, 4, 8, 4];
class se extends J {
  parseHeader() {
    var e = this.chunk.getUint16();
    18761 === e ? this.le = !0 : 19789 === e && (this.le = !1), this.chunk.le = this.le, this.headerParsed = !0;
  }
  parseTags(e, t, s = new Map()) {
    let {
      pick: i,
      skip: n
    } = this.options[t];
    i = new Set(i);
    let r = i.size > 0,
      a = 0 === n.size,
      h = this.chunk.getUint16(e);
    e += 2;
    for (let f = 0; f < h; f++) {
      let h = this.chunk.getUint16(e);
      if (r) {
        if (i.has(h) && (s.set(h, this.parseTag(e, h, t)), i.delete(h), 0 === i.size)) break;
      } else !a && n.has(h) || s.set(h, this.parseTag(e, h, t));
      e += 12;
    }
    return s;
  }
  parseTag(e, t, s) {
    let {
        chunk: i
      } = this,
      n = i.getUint16(e + 2),
      r = i.getUint32(e + 4),
      a = te[n];
    if (a * r <= 4 ? e += 8 : e = i.getUint32(e + 8), (n < 1 || n > 13) && l(`Invalid TIFF value type. block: ${s.toUpperCase()}, tag: ${t.toString(16)}, type: ${n}, offset ${e}`), e > i.byteLength && l(`Invalid TIFF value offset. block: ${s.toUpperCase()}, tag: ${t.toString(16)}, type: ${n}, offset ${e} is outside of chunk size ${i.byteLength}`), 1 === n) return i.getUint8Array(e, r);
    if (2 === n) return "" === (h = function (e) {
      for (; e.endsWith("\0");) e = e.slice(0, -1);
      return e;
    }(h = i.getString(e, r)).trim()) ? void 0 : h;
    var h;
    if (7 === n) return i.getUint8Array(e, r);
    if (1 === r) return this.parseTagValue(n, e);
    {
      let t = new (function (e) {
          switch (e) {
            case 1:
              return Uint8Array;
            case 3:
              return Uint16Array;
            case 4:
              return Uint32Array;
            case 5:
              return Array;
            case 6:
              return Int8Array;
            case 8:
              return Int16Array;
            case 9:
              return Int32Array;
            case 10:
              return Array;
            case 11:
              return Float32Array;
            case 12:
              return Float64Array;
            default:
              return Array;
          }
        }(n))(r),
        s = a;
      for (let i = 0; i < r; i++) t[i] = this.parseTagValue(n, e), e += s;
      return t;
    }
  }
  parseTagValue(e, t) {
    let {
      chunk: s
    } = this;
    switch (e) {
      case 1:
        return s.getUint8(t);
      case 3:
        return s.getUint16(t);
      case 4:
        return s.getUint32(t);
      case 5:
        return s.getUint32(t) / s.getUint32(t + 4);
      case 6:
        return s.getInt8(t);
      case 8:
        return s.getInt16(t);
      case 9:
        return s.getInt32(t);
      case 10:
        return s.getInt32(t) / s.getInt32(t + 4);
      case 11:
        return s.getFloat(t);
      case 12:
        return s.getDouble(t);
      case 13:
        return s.getUint32(t);
      default:
        l(`Invalid tiff type ${e}`);
    }
  }
}
class ie extends se {
  static canHandle(e, t) {
    return 225 === e.getUint8(t + 1) && 1165519206 === e.getUint32(t + 4) && 0 === e.getUint16(t + 8);
  }
  async parse() {
    this.parseHeader();
    let {
      options: e
    } = this;
    return e.ifd0.enabled && (await this.parseIfd0Block()), e.exif.enabled && (await this.safeParse("parseExifBlock")), e.gps.enabled && (await this.safeParse("parseGpsBlock")), e.interop.enabled && (await this.safeParse("parseInteropBlock")), e.ifd1.enabled && (await this.safeParse("parseThumbnailBlock")), this.createOutput();
  }
  safeParse(e) {
    let t = this[e]();
    return void 0 !== t.catch && (t = t.catch(this.handleError)), t;
  }
  findIfd0Offset() {
    void 0 === this.ifd0Offset && (this.ifd0Offset = this.chunk.getUint32(4));
  }
  findIfd1Offset() {
    if (void 0 === this.ifd1Offset) {
      this.findIfd0Offset();
      let e = this.chunk.getUint16(this.ifd0Offset),
        t = this.ifd0Offset + 2 + 12 * e;
      this.ifd1Offset = this.chunk.getUint32(t);
    }
  }
  parseBlock(e, t) {
    let s = new Map();
    return this[t] = s, this.parseTags(e, t, s), s;
  }
  async parseIfd0Block() {
    if (this.ifd0) return;
    let {
      file: e
    } = this;
    this.findIfd0Offset(), this.ifd0Offset < 8 && l("Malformed EXIF data"), !e.chunked && this.ifd0Offset > e.byteLength && l(`IFD0 offset points to outside of file.\nthis.ifd0Offset: ${this.ifd0Offset}, file.byteLength: ${e.byteLength}`), e.tiff && (await e.ensureChunk(this.ifd0Offset, o(this.options)));
    let t = this.parseBlock(this.ifd0Offset, "ifd0");
    return 0 !== t.size ? (this.exifOffset = t.get(34665), this.interopOffset = t.get(40965), this.gpsOffset = t.get(34853), this.xmp = t.get(700), this.iptc = t.get(33723), this.icc = t.get(34675), this.options.sanitize && (t.delete(34665), t.delete(40965), t.delete(34853), t.delete(700), t.delete(33723), t.delete(34675)), t) : void 0;
  }
  async parseExifBlock() {
    if (this.exif) return;
    if (this.ifd0 || (await this.parseIfd0Block()), void 0 === this.exifOffset) return;
    this.file.tiff && (await this.file.ensureChunk(this.exifOffset, o(this.options)));
    let e = this.parseBlock(this.exifOffset, "exif");
    return this.interopOffset || (this.interopOffset = e.get(40965)), this.makerNote = e.get(37500), this.userComment = e.get(37510), this.options.sanitize && (e.delete(40965), e.delete(37500), e.delete(37510)), this.unpack(e, 41728), this.unpack(e, 41729), e;
  }
  unpack(e, t) {
    let s = e.get(t);
    s && 1 === s.length && e.set(t, s[0]);
  }
  async parseGpsBlock() {
    if (this.gps) return;
    if (this.ifd0 || (await this.parseIfd0Block()), void 0 === this.gpsOffset) return;
    let e = this.parseBlock(this.gpsOffset, "gps");
    return e && e.has(2) && e.has(4) && (e.set("latitude", ne(...e.get(2), e.get(1))), e.set("longitude", ne(...e.get(4), e.get(3)))), e;
  }
  async parseInteropBlock() {
    if (!this.interop && (this.ifd0 || (await this.parseIfd0Block()), void 0 !== this.interopOffset || this.exif || (await this.parseExifBlock()), void 0 !== this.interopOffset)) return this.parseBlock(this.interopOffset, "interop");
  }
  async parseThumbnailBlock(e = !1) {
    if (!this.ifd1 && !this.ifd1Parsed && (!this.options.mergeOutput || e)) return this.findIfd1Offset(), this.ifd1Offset > 0 && (this.parseBlock(this.ifd1Offset, "ifd1"), this.ifd1Parsed = !0), this.ifd1;
  }
  async extractThumbnail() {
    if (this.headerParsed || this.parseHeader(), this.ifd1Parsed || (await this.parseThumbnailBlock(!0)), void 0 === this.ifd1) return;
    let e = this.ifd1.get(513),
      t = this.ifd1.get(514);
    return this.chunk.getUint8Array(e, t);
  }
  get image() {
    return this.ifd0;
  }
  get thumbnail() {
    return this.ifd1;
  }
  createOutput() {
    let e,
      t,
      s,
      i = {};
    for (t of P) if (e = this[t], !f(e)) if (s = this.canTranslate ? this.translateBlock(e, t) : Object.fromEntries(e), this.options.mergeOutput) {
      if ("ifd1" === t) continue;
      Object.assign(i, s);
    } else i[t] = s;
    return this.makerNote && (i.makerNote = this.makerNote), this.userComment && (i.userComment = this.userComment), i;
  }
  assignToOutput(e, t) {
    if (this.globalOptions.mergeOutput) Object.assign(e, t);else for (let [s, i] of Object.entries(t)) this.assignObjectToOutput(e, s, i);
  }
}
function ne(e, t, s, i) {
  var n = e + t / 60 + s / 3600;
  return "S" !== i && "W" !== i || (n *= -1), n;
}
e(ie, "type", "tiff"), e(ie, "headerLength", 10), y.set("tiff", ie);
var re = Object.freeze({
  __proto__: null,
  default: G,
  Exifr: H,
  fileParsers: m,
  segmentParsers: y,
  fileReaders: b,
  tagKeys: B,
  tagValues: V,
  tagRevivers: I,
  createDictionary: x,
  extendDictionary: C,
  fetchUrlAsArrayBuffer: S,
  readBlobAsArrayBuffer: A,
  chunkedProps: L,
  otherSegments: T,
  segments: z,
  tiffBlocks: P,
  segmentsAndBlocks: F,
  tiffExtractables: j,
  inheritables: E,
  allFormatters: M,
  Options: R,
  parse: Y
});
const ae = {
    ifd0: !1,
    ifd1: !1,
    exif: !1,
    gps: !1,
    interop: !1,
    sanitize: !1,
    reviveValues: !0,
    translateKeys: !1,
    translateValues: !1,
    mergeOutput: !1
  },
  he = Object.assign({}, ae, {
    firstChunkSize: 4e4,
    gps: [1, 2, 3, 4]
  });
async function fe(e) {
  let t = new H(he);
  await t.read(e);
  let s = await t.parse();
  if (s && s.gps) {
    let {
      latitude: e,
      longitude: t
    } = s.gps;
    return {
      latitude: e,
      longitude: t
    };
  }
}
const le = Object.assign({}, ae, {
  tiff: !1,
  ifd1: !0,
  mergeOutput: !1
});
async function oe(e) {
  let t = new H(le);
  await t.read(e);
  let s = await t.extractThumbnail();
  return s && a ? r.from(s) : s;
}
async function ue(e) {
  let t = await this.thumbnail(e);
  if (void 0 !== t) {
    let e = new Blob([t]);
    return URL.createObjectURL(e);
  }
}
const de = Object.assign({}, ae, {
  firstChunkSize: 4e4,
  ifd0: [274]
});
async function ce(e) {
  let t = new H(de);
  await t.read(e);
  let s = await t.parse();
  if (s && s.ifd0) return s.ifd0[274];
}
const pe = Object.freeze({
  1: {
    dimensionSwapped: !1,
    scaleX: 1,
    scaleY: 1,
    deg: 0,
    rad: 0
  },
  2: {
    dimensionSwapped: !1,
    scaleX: -1,
    scaleY: 1,
    deg: 0,
    rad: 0
  },
  3: {
    dimensionSwapped: !1,
    scaleX: 1,
    scaleY: 1,
    deg: 180,
    rad: 180 * Math.PI / 180
  },
  4: {
    dimensionSwapped: !1,
    scaleX: -1,
    scaleY: 1,
    deg: 180,
    rad: 180 * Math.PI / 180
  },
  5: {
    dimensionSwapped: !0,
    scaleX: 1,
    scaleY: -1,
    deg: 90,
    rad: 90 * Math.PI / 180
  },
  6: {
    dimensionSwapped: !0,
    scaleX: 1,
    scaleY: 1,
    deg: 90,
    rad: 90 * Math.PI / 180
  },
  7: {
    dimensionSwapped: !0,
    scaleX: 1,
    scaleY: -1,
    deg: 270,
    rad: 270 * Math.PI / 180
  },
  8: {
    dimensionSwapped: !0,
    scaleX: 1,
    scaleY: 1,
    deg: 270,
    rad: 270 * Math.PI / 180
  }
});
let ge = !0,
  me = !0;
if ("object" == typeof navigator) {
  let e = navigator.userAgent;
  if (e.includes("iPad") || e.includes("iPhone")) {
    let t = e.match(/OS (\d+)_(\d+)/);
    if (t) {
      let [, e, s] = t,
        i = Number(e) + .1 * Number(s);
      ge = i < 13.4, me = !1;
    }
  } else if (e.includes("OS X 10")) {
    let [, t] = e.match(/OS X 10[_.](\d+)/);
    ge = me = Number(t) < 15;
  }
  if (e.includes("Chrome/")) {
    let [, t] = e.match(/Chrome\/(\d+)/);
    ge = me = Number(t) < 81;
  } else if (e.includes("Firefox/")) {
    let [, t] = e.match(/Firefox\/(\d+)/);
    ge = me = Number(t) < 77;
  }
}
async function ye(e) {
  let t = await ce(e);
  return Object.assign({
    canvas: ge,
    css: me
  }, pe[t]);
}
class be extends c {
  constructor(...t) {
    super(...t), e(this, "ranges", new we()), 0 !== this.byteLength && this.ranges.add(0, this.byteLength);
  }
  _tryExtend(e, t, s) {
    if (0 === e && 0 === this.byteLength && s) {
      let e = new DataView(s.buffer || s, s.byteOffset, s.byteLength);
      this._swapDataView(e);
    } else {
      let s = e + t;
      if (s > this.byteLength) {
        let {
          dataView: e
        } = this._extend(s);
        this._swapDataView(e);
      }
    }
  }
  _extend(e) {
    let t;
    t = a ? r.allocUnsafe(e) : new Uint8Array(e);
    let s = new DataView(t.buffer, t.byteOffset, t.byteLength);
    return t.set(new Uint8Array(this.buffer, this.byteOffset, this.byteLength), 0), {
      uintView: t,
      dataView: s
    };
  }
  subarray(e, t, s = !1) {
    return t = t || this._lengthToEnd(e), s && this._tryExtend(e, t), this.ranges.add(e, t), super.subarray(e, t);
  }
  set(e, t, s = !1) {
    s && this._tryExtend(t, e.byteLength, e);
    let i = super.set(e, t);
    return this.ranges.add(t, i.byteLength), i;
  }
  async ensureChunk(e, t) {
    this.chunked && (this.ranges.available(e, t) || (await this.readChunk(e, t)));
  }
  available(e, t) {
    return this.ranges.available(e, t);
  }
}
class we {
  constructor() {
    e(this, "list", []);
  }
  get length() {
    return this.list.length;
  }
  add(e, t, s = 0) {
    let i = e + t,
      n = this.list.filter(t => ke(e, t.offset, i) || ke(e, t.end, i));
    if (n.length > 0) {
      e = Math.min(e, ...n.map(e => e.offset)), i = Math.max(i, ...n.map(e => e.end)), t = i - e;
      let s = n.shift();
      s.offset = e, s.length = t, s.end = i, this.list = this.list.filter(e => !n.includes(e));
    } else this.list.push({
      offset: e,
      length: t,
      end: i
    });
  }
  available(e, t) {
    let s = e + t;
    return this.list.some(t => t.offset <= e && s <= t.end);
  }
}
function ke(e, t, s) {
  return e <= t && t <= s;
}
class Oe extends be {
  constructor(t, s) {
    super(0), e(this, "chunksRead", 0), this.input = t, this.options = s;
  }
  async readWhole() {
    this.chunked = !1, await this.readChunk(this.nextChunkOffset);
  }
  async readChunked() {
    this.chunked = !0, await this.readChunk(0, this.options.firstChunkSize);
  }
  async readNextChunk(e = this.nextChunkOffset) {
    if (this.fullyRead) return this.chunksRead++, !1;
    let t = this.options.chunkSize,
      s = await this.readChunk(e, t);
    return !!s && s.byteLength === t;
  }
  async readChunk(e, t) {
    if (this.chunksRead++, 0 !== (t = this.safeWrapAddress(e, t))) return this._readChunk(e, t);
  }
  safeWrapAddress(e, t) {
    return void 0 !== this.size && e + t > this.size ? Math.max(0, this.size - e) : t;
  }
  get nextChunkOffset() {
    if (0 !== this.ranges.list.length) return this.ranges.list[0].length;
  }
  get canReadNextChunk() {
    return this.chunksRead < this.options.chunkLimit;
  }
  get fullyRead() {
    return void 0 !== this.size && this.nextChunkOffset === this.size;
  }
  read() {
    return this.options.chunked ? this.readChunked() : this.readWhole();
  }
  close() {}
}
b.set("blob", class extends Oe {
  async readWhole() {
    this.chunked = !1;
    let e = await A(this.input);
    this._swapArrayBuffer(e);
  }
  readChunked() {
    return this.chunked = !0, this.size = this.input.size, super.readChunked();
  }
  async _readChunk(e, t) {
    let s = t ? e + t : void 0,
      i = this.input.slice(e, s),
      n = await A(i);
    return this.set(n, e, !0);
  }
});
export default re;
export { H as Exifr, R as Options, M as allFormatters, L as chunkedProps, x as createDictionary, C as extendDictionary, S as fetchUrlAsArrayBuffer, m as fileParsers, b as fileReaders, fe as gps, he as gpsOnlyOptions, E as inheritables, ce as orientation, de as orientationOnlyOptions, T as otherSegments, Y as parse, A as readBlobAsArrayBuffer, ge as rotateCanvas, me as rotateCss, ye as rotation, pe as rotations, y as segmentParsers, z as segments, F as segmentsAndBlocks, B as tagKeys, I as tagRevivers, V as tagValues, oe as thumbnail, le as thumbnailOnlyOptions, ue as thumbnailUrl, P as tiffBlocks, j as tiffExtractables };